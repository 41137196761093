import Layout from "./common/layout"
import Logistics from "./service/Logistics/Logistics"
import Head from "./common/Head"
import { useFormatter } from "helpers/i18n"

const ServiceLogistics = () => {
  const { currentLocale } = useFormatter()

  return (
    <>
      <Head
        showCaptcha={true}
        title={
          currentLocale === "en"
            ? "Omni-channel logistics & fulfilment solutions"
            : "Quản lý chuỗi cung ứng đa kênh vận dụng công nghệ"
        }
        description={
          currentLocale === "en"
            ? "OnPoint helps brands improve their supply chain efficiency, increasing sales and profits, and optimizing cost structure while improving inventory levels."
            : "OnPoint giúp các nhãn hàng cải thiện hiệu quả chuỗi cung ứng, tăng doanh thu và lợi nhuận, tối ưu hóa cấu trúc chi phí, đồng thời cải thiện mức tồn kho."
        }
        image="https://s3.ap-southeast-1.amazonaws.com/public.onpoint.vn/service-4-meta.png"
      />
      <Layout>
        <Logistics />
      </Layout>
    </>
  )
}

export default ServiceLogistics
